let labelShortcutDetails = () => {
    return {
        tax_val: {
            "display_label_ar": "قــيــمـة الضريبة",
            "display_label_en": "VAT Value"
        },
        unit_price: {
            "display_label_ar": "ســعر الوحدة",
            "display_label_en": "U. Rate"
        },
        total_before_tax: {
            "display_label_ar": "المجموع قبل VAT",
            "display_label_en": "Total Before VAT"
        },
        total_after_tax: {
            "display_label_ar": "المجموع بعد VAT",
            "display_label_en": "Total After VAT"
        }
    }
}
let getLabelShortcutFromInnerData = (_slug, lang) => {
    let _labels = labelShortcutDetails();
    return _labels[_slug]['display_label_'+lang];
}
let getLabelShortcutFromSettingsPrint = ( _slug, lang, _labels_list = [], isUseLabelShortcut = false) => {
    let _label = getLabelShortcutFromInnerData(_slug, lang);
    if(_labels_list.length > 0 && isUseLabelShortcut){
        let _label_obj = _labels_list.find((_row)=> _row.slug == _slug && _row.is_active == true);
        if (_label_obj){
            _label = _label_obj['display_label_'+lang];
        }
    }
    return _label;
}
export default {getLabelShortcutFromSettingsPrint};